const { Grid } = require("@mui/material");
const { default: MKInput } = require("./MKInput");
const propTypes = require("prop-types");

const InputField = ({ multiline, ariaLabel, ...rest }) => {
  return (
    <Grid item xs={12} my={2}>
      <MKInput
        InputLabelProps={{ shrink: true }}
        fullWidth
        inputProps={{ 
          maxLength: 50,
          "aria-label": ariaLabel,
         }}
        InputProps={{
          ...(multiline ? {} : { style: { height: '45px' } }) // Applique la hauteur uniquement si ce n'est pas multiline
        }}
        multiline={multiline}
        {...rest}
      />
    </Grid>
  );
};
InputField.propTypes = {
  multiline: propTypes.bool,
  ariaLabel: propTypes.string,
};

InputField.defaultProps = {
  ariaLabel: "",
};

export default InputField;