// lib

import config from "config";
// import { enableRecaptcha } from "./useRecaptcha";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\u00C0-\u017F]{2,}$/;

export const validateEmail = (email) => emailRegex.test(email);

export const validatePhoneNumber = (number, errors, key, required = true) => {
  let error;
  if (required && !number) {
    error = "Téléphone requis";
  } else if (number) {
    if (!isMobile() && (isNaN(number) || number.length !== 10)) {
      error = "Merci de saisir un numéro de téléphone de 10 chiffres";
    }
  }
  if (error) {
    errors[key] = error;
  }
};

export const validateNpa = (npa, errors, key) => {
  const error = !npa ? "NPA requis" : (isNaN(npa) || npa.length !== 4) ? "Merci de saisir un NPA de 4 chiffres" : undefined;
  if (error) {
    errors[key] = error;
  }
}

export const isMobile = () => {
  return /android|iphone|ipad|ipod/i.test(navigator.userAgent);
}

export const tomorrow = () => {
  const date =  new Date();
  date.setDate(date.getDate() + 1);
  return date;
}

export const encodeFormData = (data) => {
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`; // Transforme en DD.MM.YYYY
  };

  return Object.keys(data).map((key) => {
    let value = data[key];
    if (key.startsWith("date")) {
      value = formatDate(value); // Formate les dates
    }
    return encodeURIComponent(key) + "=" + encodeURIComponent(value);
  }).join("&");
};

export const callbackAfterRecaptcha = (callback) => {
  // if (enableRecaptcha) {
  //   window.grecaptcha.ready(function () {
  //     window.grecaptcha
  //       .execute(config.captchaKey, { action: "contact_form" })
  //       .then(function (token) {
  //         fetch("/.netlify/functions/verify-recaptcha", {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({ token }),
  //         })
  //           .then((response) => response.json())
  //           .then((data) => {
  //             // console.log("recaptcha", data);
  //             if (data.message === "Verification successful!") {
  //               callback();
  //             } else {
  //               // console.log("Échec de la vérification reCAPTCHA.");
  //             }
  //           })
  //           .catch((error) => console.error("Erreur lors de la vérification:", error));
  //       });
  //   });
  // } else {
    callback();
  // }
}

export const createAffiliateLink = (url) => {
  const amazonDomain = "www.amazon.";
  if (url.includes(amazonDomain)) {
    try {
      const newUrl = new URL(url);
      newUrl.searchParams.set("tag", config.amazonId);
      return newUrl.toString();
    } catch (error) {
      console.error("Invalid Amazon URL:", error);
      return url;
    }
  }
  return url;
}

export const isExternal = (url) => {
  try {
    const linkUrl = new URL(url);
    return linkUrl.origin !== window.location.origin;
  } catch {
    return false;
  }
};

export const upperFirstLettre = (str) => {
  // Ajouter un espace avant chaque majuscule sauf la première
  const formatted = str.replace(/([A-Z])/g, ' $1');
  // Mettre la première lettre en majuscule et transformer le reste en minuscules
  return formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase();
};

export const copyToClipboard = (text) => {
  // Vérifie si l'API Clipboard est disponible
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text).then(() => {
      // console.log("Texte copié avec succès !");
    }).catch((error) => {
      // console.error("Erreur lors de la copie : ", error);
    });
  } else {
    // Méthode alternative pour les anciens navigateurs
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed"; // Prévenir le scroll de la page
    textarea.style.opacity = "0"; // Rendre invisible
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      document.execCommand("copy");
      console.log("Texte copié avec succès (méthode alternative) !");
    } catch (error) {
      console.error("Erreur lors de la copie (méthode alternative) : ", error);
    } finally {
      document.body.removeChild(textarea);
    }
  }
}


export const getImage = (filename, purge = false, width) => {
    // URL de base
    let url = `https://ik.imagekit.io/${config.imagekitId}/images/${filename}`;
  
    // Création d'un tableau pour stocker les paramètres de la query string
    let params = [];
  
    // Si width est défini, ajoute le paramètre de taille
    if (width) {
      params.push(`tr=w-${width}`);
    }
  
    // Si purge est true, ajoute un timestamp pour forcer l'actualisation
    if (purge) {
      params.push(`version=${Date.now()}`);
    }

    params.push("tr=cmpr-75");
  
    // Si des paramètres existent, les ajouter à l'URL
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
  
    return url;
  };

export const buildEmailLink = (emailAdress) => <a className="underline" href={`mailto:${emailAdress}`}>{emailAdress}</a>

export const buildPhoneLink = (phone) => <a className="underline" href={`tel:${phone}`}>{phone}</a>
