import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import propTypes from "prop-types";
import MKTypography from "./MKTypography";

const CheckboxField = ({ checked, label, onChange, disabled, error, ...rest }) => {
  return (
    <Grid item xs={12} my={2}>
      <MKBox display="flex" ml={1} alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              disabled={disabled}
              color="primary"
              className={error ? "Mui-error" : ""}
              {...rest}
            />
          }
          label={
            <MKTypography
              variant="button"
              color={error ? "error" : "text"}
              fontWeight="regular"
              sx={{
                cursor: disabled ? "not-allowed" : "pointer",
                userSelect: "none",
              }}
            >
              {label}
            </MKTypography>
          }
        />
      </MKBox>
    </Grid>
  );
};

// Valeurs par défaut pour les props
CheckboxField.defaultProps = {
  checked: false,
  label: "",
  disabled: false,
  error: false,
  onChange: () => {}, // Fonction par défaut si `onChange` n'est pas fournie
};

// Définition des types de props
CheckboxField.propTypes = {
  checked: propTypes.bool,
  label: propTypes.string, // `label` doit être une chaîne de caractères
  disabled: propTypes.bool,
  onChange: propTypes.func, // Permet la gestion des modifications
  error: propTypes.bool,
};

export default CheckboxField;
