import { useMediaQuery } from "@mui/material";
import { getImage } from "lib";
import React from "react";

/**
 * Exemple d'appel :
 *       <BoxImage
 *          image="article.png"
 *          theme={theme}
 *          mkBoxComponent={
 *            <MKBox
 *              minHeight={{ xs: "80vh", md: "80vh" }}
 *              width="100%"
 *              sx={{
 *                display: "grid",
 *                placeItems: "center",
 *                paddingTop: { xs: "0vh", sm: "28vh", md: "40vh", lg: "10vh" },
 *                paddingBottom: { xs: "0vh", sm: "23vh", md: "28vh", lg: "0vh" },
 *                backgroundSize: "cover",
 *                backgroundPosition: "top",
 *              }}
 *            />
 *          }
 *        >
 *        ...
 *       </BoxImage>
 * @param {*} param0
 * @returns
 */

const BoxImage = ({ children, image, purge = false, theme, mkBoxComponent }) => {
  // Déterminer la taille de l'image en fonction du breakpoint
  const breakpoint = useMediaQuery(theme.breakpoints.up("xl")) ? 1792 :
                     useMediaQuery(theme.breakpoints.only("lg")) ? 1792 :
                     useMediaQuery(theme.breakpoints.only("md")) ? 1280 :
                     useMediaQuery(theme.breakpoints.only("sm")) ? 960 : 600;

  const imageModule = getImage(image, purge, breakpoint);

  // Ajouter/override les props pour mkBoxComponent
  const boxWithBgImage = React.cloneElement(mkBoxComponent, {
    sx: {
      backgroundImage: imageModule ? `url(${imageModule})` : "none",
      ...mkBoxComponent.props.sx,
    },
    children,
  });

  return boxWithBgImage;
};

export default BoxImage;
