// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import MailIcon from '@mui/icons-material/Mail';

// Material Kit 2 React components
import config from "config";

export const footer = {
  brand: {
    image: config.logo,
    route: "/",
  },
  socials: [
    {
      name: "facebook",
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/pg/Garderie-aux-petits-pas-348535595992391/about/",
    },
    {
      name: "adresse",
      icon: <PlaceIcon />,
      link: config.mapLink,
    },
    {
      name: "téléphone",
      icon: <PhoneIcon />,
      link: `tel:${config.phoneNumber}`,
    },
    {
      name: "mail",
      icon: <MailIcon />,
      link: `mailto:${config.emailAdress}`,
    },
  ],
  menus: [
    {
      name: "info",
      items: [
        { name: "contact", route: "/contact" },
        { name: "avis", route: "/nouvel-avis" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "mentions-legales", route: "/mentions-legales" },
        { name: "politique-confidentialite", route: "/politique-de-confidentialite" },
        { name: "cgu", route: "/conditions-generales-utilisation" },
        { name: "politique-cookies", route: "/politique-cookies" },
      ],
    },
  ],
};
