export const article = `
La Garderie Aux Petits Pas est fermée durant les jours fériés vaudois suivants :

~~~bullet
Nouvel An : lu. 1 janvier et ma. 2 janvier 2024
£ Vendredi Saint : ve. 29 mars 2024
£ Lundi de Pâques : lu. 1 avril 2024
£ Jeudi de l’Ascension : je. 9 mai 2024
£ Lundi de Pentecôte : lu. 20 mai 2024
£ Fête nationale : je. 1er août 2024
£ Lundi du Jeûne : lu. 16 septembre 2024
£ Noël : me. 25 décembre 2024
~~~

## Fêtes de fin d’année
Nous vous informons également qu’a partir de 2024, la garderie sera désormais fermée pendant les fêtes de fin d’année. Cette année, la fermeture sera effective du lundi 23 décembre 2024 au vendredi 3 janvier 2025 inclus.
`