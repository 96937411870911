export const article = `

## Téléchargement du dossier d’inscription

Merci de télécharger le [dossier d’inscription](https://garderieauxpetitspas.ch/wp-content/uploads/2023/01/dossier-inscription-2023.pdf) de votre enfant et de nous le renvoyer à <span class="email"></span>.

## Demande du dossier d’inscription

Si vous le souhaitez, nous vous envoyons ce document par mail. Il vous suffit de nous contacter à l’aide du [formulaire de contact](/contact).

## Inscription en ligne

Remplissez le [formulaire d’inscription en ligne](/inscription-en-ligne) pour inscrire votre enfant en quelques étapes.

`