import config from "config";

const imagekitId = config.imagekitId;
const adeline = `https://ik.imagekit.io/${imagekitId}/images/adeline_2560px.webp`;
const anna3 = `https://ik.imagekit.io/${imagekitId}/images/anna3_300px.webp`;
const Barbara1 = `https://ik.imagekit.io/${imagekitId}/images/Barbara1_768px.webp`;
const Christelle = `https://ik.imagekit.io/${imagekitId}/images/Christelle_735px.webp`;
const Ivana2 = `https://ik.imagekit.io/${imagekitId}/images/Ivana2_150px.webp`;
const Larissa = `https://ik.imagekit.io/${imagekitId}/images/Larissa__845px.webp`;
const Marietou = `https://ik.imagekit.io/${imagekitId}/images/Marietou_354px.webp`;
const Noa = `https://ik.imagekit.io/${imagekitId}/images/Noa_300px.webp`;
const Sandrine = `https://ik.imagekit.io/${imagekitId}/images/Sandrine_300px.webp`;
const Veronique = `https://ik.imagekit.io/${imagekitId}/images/Veronique_300px.webp`;

export const team = [
    {
      name: "Sandrine Desgalier",
      job: "Co-Directrice générale, Directrice pédagogique et éducatrice",
      image: Sandrine
    },
    {
      name: "Marietou Séne",
      job: "Co-Directrice générale, éducatrice",
      image: Marietou
    },
    {
      name: "Anna",
      job: "Collaboratrice",
      image: anna3
    },
    {
      name: "Larissa",
      job: "Collaboratrice",
      image: Larissa
    },
    {
      name: "Adeline",
      job: "Collaboratrice",
      image: adeline
    },
    {
      name: "Christelle",
      job: "Collaboratrice",
      image: Christelle
    },
    {
      name: "Ivana",
      job: "Collaboratrice",
      image: Ivana2
    },
    {
      name: "Véronique",
      job: "Collaboratrice",
      image: Veronique
    },
    {
      name: "Noa",
      job: "Collaboratrice",
      image: Noa
    },
    {
      name: "Barbara",
      job: "Collaboratrice",
      image: Barbara1
    }
  ];
  