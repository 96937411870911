// import config from "config";
// import { useEffect } from "react";

export const enableRecaptcha = window.location.hostname !== "localhost";

export const useRecaptcha = () => {
  // useEffect(() => {
  //   if (enableRecaptcha) {
  //     const scriptId = "recaptcha-script";
      
  //     // Éviter de charger plusieurs fois le script
  //     if (!document.getElementById(scriptId)) {
  //       const script = document.createElement("script");
  //       script.src = `https://www.google.com/recaptcha/api.js?render=${config.captchaKey}`;
  //       script.async = true;
  //       script.id = scriptId;
  //       document.body.appendChild(script);

  //       return () => {
  //         document.body.removeChild(script);
  //       };
  //     }
  //   }
  // }, [enableRecaptcha]);
}
