/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { copyrightDate } from "App";
import { footer } from "data/data";
import { useTranslation } from "react-i18next";
import { getImage } from "lib";
import config from "config";

function DefaultFooter() {
  const { t } = useTranslation();
  const { brand, socials, menus } = footer;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={getImage(brand.image)}
                  alt={t("title")}
                  maxWidth={`${config.logoSize || "2.9rem"}`}
                  mb={2}
                />
              </Link>
              <Link to={brand.route}>
                <MKTypography
                  variant="body2"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  {t("title")}
                </MKTypography>
              </Link>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ name, icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  alt={name}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          <Grid item xs={6} md={2} sx={{ mb: 3 }} />
          <Grid item xs={6} md={2} sx={{ mb: 3 }} />
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                // textTransform="capitalize"
                mb={1}
              >
                {t(`footer.${title}`)}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        // textTransform="capitalize"
                      >
                        {t(`footer.${name}`)}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        // textTransform="capitalize"
                      >
                        {t(`footer.${name}`)}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography variant="button" fontWeight="regular">
              All rights reserved. Copyright &copy; {copyrightDate} {t("title")}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DefaultFooter;
