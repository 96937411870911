export const article = `
<p>
A la Garderie Aux Petits Pas, nous croyons que chaque enfant est unique et mérite un environnement qui favorise son épanouissement, sa sécurité et son bonheur. Notre mission est d’accompagner chaque enfant dans son développement global, tout en créant un espace sécurisant, tant sur le plan physique qu’émotionnel.
</p>
<p>
Nous croyons qu’il n’existe pas une seule méthode pour bien accompagner un enfant. C’est pourquoi nous ne suivons pas une pédagogie unique, mais nous nous inspirons de différentes approches, en veillant à toujours remettre en question et à donner du sens à nos pratiques.
</p>
<p>
Nous avons à cœur de soutenir vos enfants dans leurs apprentissages et leurs découvertes. En favorisant leur autonomie et en les encourageant à exprimer leur créativité, nous leur donnons les outils nécessaires pour grandir en confiance.
</p>
<p>
Notre équipe éducative adopte une approche bienveillante, respectant le rythme et les besoins de chaque enfant. Nous travaillons main dans la main avec les parents pour établir une relation de confiance et garantir une cohérence dans l’accompagnement. Ensemble, nous formons une équipe pour soutenir le développement harmonieux de
chaque enfant.
</p>

## Des Activités Variées et Stimulantes

Pour éveiller la curiosité et nourrir l’imagination des enfants, nous offrons un large éventail d’activités adaptées à chaque âge :

- Sorties en forêt pour découvrir la nature et développer leur sens de l’observation.
- Ateliers de musique pour éveiller leurs sens, stimuler la créativité, travailler le rythme et leur mémoire.
- Bricolage et peinture pour exprimer leur imagination et développer leur motricité fine.
- Parcours de motricité pour encourager leur développement physique.
- Moments de lecture pour développer la mémoire, l’imagination, la concentration et le langage.
- Yoga pour enfants pour favoriser leur concentration, leur relaxation et leur bien- être.
- Cuisine
- Spectacle de marionnettes
- Etc.

<p>
À La Garderie Aux Petits Pas, nous veillons à ce que chaque journée soit une aventure enrichissante et joyeuse pour vos enfants. Nous leur offrons un environnement où ils peuvent explorer, jouer, apprendre et surtout s’épanouir dans un climat de bienveillance.
</p>
<p>
Nous serions ravis de vous rencontrer et de vous présenter notre équipe et notre espace de vie dédié aux enfants.
</p>
<p>
Prenez rendez-vous par mail à <span class="email"></span>
</p>

`