import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const site = process.env.REACT_APP_SITE;

// console.log(">>>>>", process.env.REACT_APP_SITE);

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'replaceSpace',
    process(value, key, options, translator) {
      return value.replace(/ :/g, '\u00A0:').replace(/ \?/g, '\u00A0?');
    }
  })
  .init({
    supportedLngs: ['fr'],
    fallbackLng: 'fr',
    backend: {
      loadPath: `/locales/${site}/{{lng}}/translation.json`,
      queryStringParams: { v: Date.now() }, // Ajoute un cache-buster pour éviter la mise en cache
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    debug: false,
    postProcess: ['replaceSpace'],
  })
;
export default i18n;
