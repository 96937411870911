/* eslint-disable react/no-unknown-property */
import { Grid, MenuItem, Modal, Slide } from "@mui/material";
import DefaultHeader from "components/DefaultHeader";
import InputField from "components/InputField";
import InputNpaField from "components/InputNpaField";
import InputPhoneField from "components/InputPhoneField";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKPagination from "components/MKPagination";
import MKTypography from "components/MKTypography";
import SectionTitle from "components/SectionTitle";
import config from "config";
import { routes } from "data/data";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { callbackAfterRecaptcha, encodeFormData, tomorrow, validateEmail, validateNpa, validatePhoneNumber } from "lib";
import { useRecaptcha } from "lib/useRecaptcha";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const childInfoInit = {
  nomEnfant: "", 
  prenomEnfant: "", 
  dateNaissanceEnfant: "", 
  sexeEnfant: "", 
  adresseEnfant: "", 
  npaEnfant: "", 
  localiteEnfant: "",
  assuranceMaladieEnfant: "", 
  assuranceRCEnfant: ""
};

const motherInfoInit = {
  nomMere: "", 
  prenomMere: "", 
  adresseMere: "", 
  npaMere: "", 
  localiteMere: "", 
  telephoneMere: "", 
  emailMere: "",
  professionMere: "", 
  employeurMere: ""
};

const fatherInfoInit = {
  nomPere: "", 
  prenomPere: "", 
  adressePere: "", 
  npaPere: "", 
  localitePere: "", 
  telephonePere: "", 
  emailPere: "",
  professionPere: "", 
  employeurPere: ""
};

const dayChoicesInit = {
  choixLundi: "", 
  choixMardi: "", 
  choixMercredi: "", 
  choixJeudi: "", 
  choixVendredi: "", 
  dateDebut: ""
};

const bgImage = "pexels-yankrukov-8613121.webp";

// Composant principal du formulaire
const Signup = () => {
  const { t } = useTranslation();
  const steps = ["Infos enfant", "Infos mère", "Infos père", "Choix jours"];
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});

  const [childInfo, setChildInfo] = useState(childInfoInit);
  const [motherInfo, setMotherInfo] = useState(motherInfoInit);
  const [fatherInfo, setFatherInfo] = useState(fatherInfoInit);
  const [dayChoices, setDayChoices] = useState(dayChoicesInit);

  const [showDialog, setShowDialog] = useState(false);
  const toggleModal = () => setShowDialog(!showDialog);

  // Gestion des changements de données
  const handleChangeChildInfo = useCallback((e) => {
    const { name, value } = e.target;
    setChildInfo((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleChangeMotherInfo = useCallback((e) => {
    const { name, value } = e.target;
    setMotherInfo((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleChangeFatherInfo = useCallback((e) => {
    const { name, value } = e.target;
    setFatherInfo((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleChangeDayChoices = useCallback((e) => {
    const { name, value } = e.target;
    setDayChoices((prev) => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    setShowDialog(false); // Clear success message when the form type changes
  }, [activeStep]);

  useRecaptcha();

  const sendForm = () => {
    if (validate()) {
      const templateParams = {
        ...childInfo,
        ...motherInfo,
        ...fatherInfo,
        ...dayChoices,
      };
  
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({ "form-name": config.signupFormName, ...templateParams }),
      })
        .then(() => {
          window.location.href = "/confirmation-inscription";
        })
        .catch((error) => {
          console.error("Erreur de soumission : " + error);
          setShowDialog(true);
        });
    }
  };

  
  // Validation par étape
  const validate = () => {
    let tempErrors = {};
    
    if (activeStep === 0) {
      if (!childInfo.nomEnfant) tempErrors.nomEnfant = "Nom de l'enfant requis";
      if (!childInfo.prenomEnfant) tempErrors.prenomEnfant = "Prénom de l'enfant requis";
      if (!childInfo.dateNaissanceEnfant) tempErrors.dateNaissanceEnfant = "Date de naissance requise";
      if (!childInfo.sexeEnfant) tempErrors.sexeEnfant = "Sexe requis";
      if (!childInfo.adresseEnfant) tempErrors.adresseEnfant = "Adresse requise";
      validateNpa(childInfo.npaEnfant, tempErrors, "npaEnfant");
      if (!childInfo.localiteEnfant) tempErrors.localiteEnfant = "Localité requise";
      if (!childInfo.assuranceMaladieEnfant) tempErrors.assuranceMaladieEnfant = "Assurance maladie/accident requise";
      if (!childInfo.assuranceRCEnfant) tempErrors.assuranceRCEnfant = "Assurance RC requise";
    }
  
    if (activeStep === 1) {
      if (!motherInfo.nomMere) tempErrors.nomMere = "Nom de la mère requis";
      if (!motherInfo.prenomMere) tempErrors.prenomMere = "Prénom de la mère requis";
      if (!motherInfo.adresseMere) tempErrors.adresseMere = "Adresse requise";
      validateNpa(motherInfo.npaMere, tempErrors, "npaMere");
      if (!motherInfo.localiteMere) tempErrors.localiteMere = "Localité requise";
      validatePhoneNumber(motherInfo.telephoneMere, tempErrors, "telephoneMere");
      if (!motherInfo.emailMere || !validateEmail(motherInfo.emailMere)) tempErrors.emailMere = "Email valide requis";
      if (!motherInfo.professionMere) tempErrors.professionMere = "Profession requise";
      if (!motherInfo.employeurMere) tempErrors.employeurMere = "Employeur requis";
    }
  
    if (activeStep === 2) {
      if (!fatherInfo.nomPere) tempErrors.nomPere = "Nom du père requis";
      if (!fatherInfo.prenomPere) tempErrors.prenomPere = "Prénom du père requis";
      if (!fatherInfo.adressePere) tempErrors.adressePere = "Adresse requise";
      validateNpa(fatherInfo.npaPere, tempErrors, "npaPere");
      if (!fatherInfo.localitePere) tempErrors.localitePere = "Localité requise";
      validatePhoneNumber(fatherInfo.telephonePere, tempErrors, "telephonePere");
      if (!fatherInfo.emailPere || !validateEmail(fatherInfo.emailPere)) tempErrors.emailPere = "Email valide requis";
      if (!fatherInfo.professionPere) tempErrors.professionPere = "Profession requise";
      if (!fatherInfo.employeurPere) tempErrors.employeurPere = "Employeur requis";
    }
  
    if (activeStep === 3) {
      if (!dayChoices.choixLundi) tempErrors.choixLundi = "Choix pour Lundi requis";
      if (!dayChoices.choixMardi) tempErrors.choixMardi = "Choix pour Mardi requis";
      if (!dayChoices.choixMercredi) tempErrors.choixMercredi = "Choix pour Mercredi requis";
      if (!dayChoices.choixJeudi) tempErrors.choixJeudi = "Choix pour Jeudi requis";
      if (!dayChoices.choixVendredi) tempErrors.choixVendredi = "Choix pour Vendredi requis";
      if (!dayChoices.dateDebut || new Date(dayChoices.dateDebut.split(".").reverse().join("-")) <= new Date()) {
        tempErrors.dateDebut = "Date de début valide requise (doit être ultérieure à aujourd'hui)";
      }
    }
  
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  

  const handleNext = () => {    
    if (validate()) setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChangeStep = (index) => setActiveStep(index);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Si toutes les validations passent, vous pouvez envoyer le formulaire ici
      // console.log("Formulaire validé, prêt pour l'envoi !");
      callbackAfterRecaptcha(() => sendForm());
    }
  };
  
  return (
    <>
      <DefaultNavbar routes={routes} source="name" sticky />
      <DefaultHeader title={t("articles.inscription-en-ligne.title")} bgImage={bgImage} description={t("articles.inscription-en-ligne.summary")} simple>
        <form name={config.signupFormName} method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={(e) => e.preventDefault()}>
          <input type="hidden" name="form-name" value={config.signupFormName} />
          <p style={{display: 'none'}}>
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <Grid container justifyContent="center" sx={{ mt: 5 }}>
            <MKPagination>
              {steps.map((label, index) => (
                <MKPagination
                  key={index}
                  item
                  disabled={activeStep < index}
                  active={activeStep === index}
                  onClick={() => handleChangeStep(index)}
                >
                  {index + 1}
                </MKPagination>
              ))}
            </MKPagination>
          </Grid>

          {activeStep === 0 && (
            <>
              <SectionTitle title="Identité de l’enfant" />
              <InputField label="Nom" name="nomEnfant" value={childInfo.nomEnfant} onChange={handleChangeChildInfo} error={!!errors.nomEnfant} helperText={errors.nomEnfant} />
              <InputField label="Prénom(s)" name="prenomEnfant" value={childInfo.prenomEnfant} onChange={handleChangeChildInfo} error={!!errors.prenomEnfant} helperText={errors.prenomEnfant} />
              <InputField label="Date de naissance" name="dateNaissanceEnfant" type="date" value={childInfo.dateNaissanceEnfant} onChange={handleChangeChildInfo} error={!!errors.dateNaissanceEnfant} helperText={errors.dateNaissanceEnfant} InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }} />
              <InputField label="Sexe" name="sexeEnfant" select value={childInfo.sexeEnfant} onChange={handleChangeChildInfo} error={!!errors.sexeEnfant} helperText={errors.sexeEnfant}>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="M">M</MenuItem>
              </InputField>
              <InputField label="Adresse (rue et numéro)" name="adresseEnfant" value={childInfo.adresseEnfant} onChange={handleChangeChildInfo} error={!!errors.adresseEnfant} helperText={errors.adresseEnfant} />
              <InputNpaField label="NPA" name="npaEnfant" value={childInfo.npaEnfant} onChange={handleChangeChildInfo} error={!!errors.npaEnfant} helperText={errors.npaEnfant} />
              <InputField label="Localité" name="localiteEnfant" value={childInfo.localiteEnfant} onChange={handleChangeChildInfo} error={!!errors.localiteEnfant} helperText={errors.localiteEnfant} />

              <SectionTitle title="Assurances de l’enfant" />
              <InputField label="Assurance maladie/accident" name="assuranceMaladieEnfant" value={childInfo.assuranceMaladieEnfant} onChange={handleChangeChildInfo} error={!!errors.assuranceMaladieEnfant} helperText={errors.assuranceMaladieEnfant} />
              <InputField label="Assurance RC" name="assuranceRCEnfant" value={childInfo.assuranceRCEnfant} onChange={handleChangeChildInfo} error={!!errors.assuranceRCEnfant} helperText={errors.assuranceRCEnfant} />
            </>
          )}

          {activeStep === 1 && (
            <>
              <SectionTitle title="Identité de la mère" />
              <InputField label="Nom de la mère" name="nomMere" value={motherInfo.nomMere} onChange={handleChangeMotherInfo} error={!!errors.nomMere} helperText={errors.nomMere} />
              <InputField label="Prénom(s) de la mère" name="prenomMere" value={motherInfo.prenomMere} onChange={handleChangeMotherInfo} error={!!errors.prenomMere} helperText={errors.prenomMere} />
              <InputField label="Adresse (rue et numéro)" name="adresseMere" value={motherInfo.adresseMere} onChange={handleChangeMotherInfo} error={!!errors.adresseMere} helperText={errors.adresseMere} />
              <InputNpaField label="NPA" name="npaMere" value={motherInfo.npaMere} onChange={handleChangeMotherInfo} error={!!errors.npaMere} helperText={errors.npaMere} />
              <InputField label="Localité" name="localiteMere" value={motherInfo.localiteMere} onChange={handleChangeMotherInfo} error={!!errors.localiteMere} helperText={errors.localiteMere} />
              <InputPhoneField label="Téléphone" name="telephoneMere" value={motherInfo.telephoneMere} onChange={handleChangeMotherInfo} error={!!errors.telephoneMere} helperText={errors.telephoneMere} />
              <InputField label="Adresse e-mail" name="emailMere" type="email" value={motherInfo.emailMere} onChange={handleChangeMotherInfo} error={!!errors.emailMere} helperText={errors.emailMere} />

              <SectionTitle title="Profession de la mère" />
              <InputField label="Profession" name="professionMere" value={motherInfo.professionMere} onChange={handleChangeMotherInfo} error={!!errors.professionMere} helperText={errors.professionMere} />
              <InputField label="Employeur (nom, lieu, téléphone)" name="employeurMere" multiline rows={4} value={motherInfo.employeurMere} onChange={handleChangeMotherInfo} error={!!errors.employeurMere} helperText={errors.employeurMere} />
            </>
          )}

          {activeStep === 2 && (
            <>
              <SectionTitle title="Identité du père" />
              <InputField label="Nom du père" name="nomPere" value={fatherInfo.nomPere} onChange={handleChangeFatherInfo} error={!!errors.nomPere} helperText={errors.nomPere} />
              <InputField label="Prénom(s) du père" name="prenomPere" value={fatherInfo.prenomPere} onChange={handleChangeFatherInfo} error={!!errors.prenomPere} helperText={errors.prenomPere} />
              <InputField label="Adresse (rue et numéro)" name="adressePere" value={fatherInfo.adressePere} onChange={handleChangeFatherInfo} error={!!errors.adressePere} helperText={errors.adressePere} />
              <InputNpaField label="NPA" name="npaPere" value={fatherInfo.npaPere} onChange={handleChangeFatherInfo} error={!!errors.npaPere} helperText={errors.npaPere} />
              <InputField label="Localité" name="localitePere" value={fatherInfo.localitePere} onChange={handleChangeFatherInfo} error={!!errors.localitePere} helperText={errors.localitePere} />
              <InputPhoneField label="Téléphone" name="telephonePere" value={fatherInfo.telephonePere} onChange={handleChangeFatherInfo} error={!!errors.telephonePere} helperText={errors.telephonePere} />
              <InputField label="Adresse e-mail" name="emailPere" type="email" value={fatherInfo.emailPere} onChange={handleChangeFatherInfo} error={!!errors.emailPere} helperText={errors.emailPere} />

              <SectionTitle title="Profession du père" />
              <InputField label="Profession" name="professionPere" value={fatherInfo.professionPere} onChange={handleChangeFatherInfo} error={!!errors.professionPere} helperText={errors.professionPere} />
              <InputField label="Employeur (nom, lieu, téléphone)" name="employeurPere" multiline rows={4} value={fatherInfo.employeurPere} onChange={handleChangeFatherInfo} error={!!errors.employeurPere} helperText={errors.employeurPere} />
            </>
          )}

          {activeStep === 3 && (
            <>
              <SectionTitle title="Choix par jour" />
              {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"].map((day) => (
                <InputField key={day} select label={day} name={`choix${day}`} value={dayChoices[`choix${day}`]} onChange={handleChangeDayChoices} error={!!errors[`choix${day}`]} helperText={errors[`choix${day}`]}>
                  <MenuItem value="Non souhaité">
                    <em>Non souhaité</em>
                  </MenuItem>
                  <MenuItem value="Jour complet (6h30-18h30) - CHF 122">Jour complet (6h30-18h30) - CHF 122</MenuItem>
                  <MenuItem value="2/3 jour (06h30-14h) - CHF 104.50">2/3 jour (06h30-14h) - CHF 104.50</MenuItem>
                  <MenuItem value="2/3 jour (11h-18h30) - CHF 104.50">2/3 jour (11h-18h30) - CHF 104.50</MenuItem>
                  <MenuItem value="1/2 jour (06h30-11h) - CHF 63">1/2 jour (06h30-11h) - CHF 63</MenuItem>
                  <MenuItem value="1/2 jour (14h–18h30) - CHF 63">1/2 jour (14h–18h30) - CHF 63</MenuItem>
                </InputField>
              ))}
              <InputField label="Début du placement souhaité pour" name="dateDebut" type="date" value={dayChoices.dateDebut} onChange={handleChangeDayChoices} error={!!errors.dateDebut} helperText={errors.dateDebut} InputProps={{ inputProps: { min: tomorrow().toISOString().split("T")[0] } }} />
              <MKTypography variant="body2" color="text" mt={3}>
                Pour information, voici comment se calcule le total mensuel :
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={3}>
                <b>= total hebdomadaire x 52 semaines annuelles, le tout divisé par 12 mois</b>
              </MKTypography>
            </>
          )}

          <div>
            <MKButton disabled={activeStep === 0} onClick={handleBack}>Retour</MKButton>
            {activeStep === steps.length - 1 ? (
              <MKButton variant="gradient" color="info" onClick={handleSubmit}>Envoyer</MKButton>
            ) : (
              <MKButton variant="gradient" color="info" onClick={handleNext}>Suivant</MKButton>
            )}
          </div>
          {Object.keys(errors).length !== 0 && (
          <MKTypography variant="body2" color="error" align="center" mt={2}>
            Certaines informations sont incorrectes ou manquantes. Veuillez vérifier les champs en
            rouge et réessayer.
          </MKTypography>
        )}

        </form>
      </DefaultHeader>

      {showDialog && (
        <Modal open={showDialog} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showDialog} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <Grid container justifyContent="center" sx={{ mt: 5 }}>
                <MKBox display="flex" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">Erreur lors de la création de l’inscription</MKTypography>
                  {/* <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleSuccessModal} /> */}
                </MKBox>
              </Grid>
              <Grid container justifyContent="center" sx={{ my: 5 }}>
                <MKButton variant="gradient" color="info" onClick={toggleModal}>
                  Fermer
                </MKButton>
              </Grid>
            </MKBox>
          </Slide>
        </Modal>
      )}

    </>
  );
};

export default Signup;
