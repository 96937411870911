import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Grid } from "@mui/material";
import MainFooter from "examples/Footers/MainFooter";
import propTypes from "prop-types";
import { Helmet } from "react-helmet";
import config from "config";
import theme from "assets/theme";
import BoxImage from "./BoxImage";

const DefaultHeader = ({
  expanded,
  children,
  minHeight,
  bgImage,
  showTitle,
  title,
  description,
  subtitle,
}) => {
  return (
    <MKBox bgColor="white">
      <BoxImage
        image={bgImage}
        theme={theme}
        mkBoxComponent={
          <MKBox
            minHeight={minHeight}
            width="100%"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          />
        }
      />
      <Card
        sx={{
          p: 2,
          mx: expanded ? 0 : { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 6, sm: 12 }}>
          <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={expanded ? 0 : 7}
                  mx={expanded ? 0 : { xs: "auto", sm: 6, md: 1 }}
                >
                  <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                  </Helmet>
                  {showTitle && (
                    <>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                      >
                        <MKTypography variant="h1" fontSize="2.4rem">
                          {title}
                        </MKTypography>
                      </MKBox>
                      {subtitle}
                    </>
                  )}
                  {children}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      {!config.hideFooter && <MainFooter />}
    </MKBox>
  );
};

DefaultHeader.propTypes = {
  expanded: propTypes.bool,
  children: propTypes.node,
  minHeight: propTypes.string,
  bgImage: propTypes.string,
  showTitle: propTypes.bool,
  title: propTypes.string,
  description: propTypes.string,
  subtitle: propTypes.node,
};

DefaultHeader.defaultProps = {
  expanded: false,
  minHeight: "30rem",
  showTitle: true,
  subtitle: undefined,
};

export default DefaultHeader;
