import ContactUsWithData from "pages/ContactUs/ContactUsWithData";
import Signup from "pages/Signup";
import Article from "pages/Presentation/Article";

export const routes = [
  {
    name: "la-garderie",
    collapse: [
      {
        name: "pedagogie",
        component: <Article name="pedagogie"></Article>,
        route: "/pedagogie",
      },
      {
        name: "horaires-garderie",
        component: <Article name="horaires-garderie"></Article>,
        route: "/horaires-garderie",
      },
      {
        name: "vacances-feries",
        component: <Article name="vacances-feries"></Article>,
        route: "/vacances-feries",
      },
      {
        name: "galerie",
        component: <Article name="galerie" expanded></Article>,
        route: "/galerie",
      },
    ],
  },
  {
    name: "inscriptions",
    collapse: [
      {
        name: "inscription-en-ligne",
        component: <Signup/>,
        route: "/inscription-en-ligne",
      },
      {
        name: "dossier-inscription",
        component: <Article name="dossier-inscription"></Article>,
        route: "/dossier-inscription",
      },
      {
        name: "tarifs",
        component: <Article name="tarifs"></Article>,
        route: "/tarifs",
      },
    ],
  },
  {
    name: "informations-pratiques",
    collapse: [
      {
        name: "contacts",
        component: <ContactUsWithData/>,
        route: "/contacts",
      },
      {
        name: "rendez-vous-parents",
        component: <Article name="rendez-vous-parents"></Article>,
        route: "/rendez-vous-parents",
      },
    ],
  },
  {
    name: "vie-quotidienne",
    collapse: [
      {
        name: "menu-semaine",
        component: <Article name="menu-semaine"></Article>,
        route: "/menu-semaine",
      },
    ],
  },
];
