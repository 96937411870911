import { article as pedagogie } from "./content/pedagogie.js";
import { article as horaires_garderie } from "./content/horaires-garderie.js";
import { article as vacances_feries } from "./content/vacances-feries.js";
import { article as dossier_inscription } from "./content/dossier-inscription.js";
import { article as tarifs } from "./content/tarifs.js";
import { article as rendez_vous_parents } from "./content/rendez-vous-parents.js";
import { article as galerie } from "./content/galerie.js";
import { article as menu_semaine } from "./content/menu-semaine.js";
import config from "config.js";

const img_default = "goumbik.webp";


export const articles = {
  home: {
    img: "landing_2065px.webp",
  },
  pedagogie: {
    image: img_default,
    content: pedagogie,
    updateDate: "22.10.2024",
  },
  "horaires-garderie": {
    image: img_default,
    content: horaires_garderie,
    updateDate: "22.10.2024",
  },
  "vacances-feries": {
    image: img_default,
    content: vacances_feries,
    updateDate: "22.10.2024",
  },
  "dossier-inscription": {
    image: "pexels-yankrukov-8613121.webp",
    content: dossier_inscription,
    updateDate: "22.10.2024",
  },
  tarifs: {
    image: "pexels-yankrukov-8613121.webp",
    content: tarifs,
    updateDate: "22.10.2024",
    expanded: true,
  },
  "rendez-vous-parents": {
    image: config.contactImg,
    content: rendez_vous_parents,
    updateDate: "22.10.2024",
  },
  galerie: {
    image: img_default,
    content: galerie,
    updateDate: "22.10.2024",
    expanded: true,
  },
  "menu-semaine": {
    image: "pexels-cottonbro-3662628.webp",
    content: menu_semaine,
    updateDate: "22.10.2024",
  },
};
