import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import propTypes from "prop-types";

const SectionTitle = ({ title, variant, component }) => (
  <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
    <MKTypography variant={variant} component={component} my={3}>
      {title}
    </MKTypography>
  </Grid>
);

SectionTitle.propTypes = {
  title: propTypes.string,
  variant: propTypes.string,
  component: propTypes.string,
};

SectionTitle.defaultProps = {
  variant: "h3",
  component: "p",
};

export default SectionTitle;