
export const data = [
  {
    "original": "interieur/20220521_123519-2048x1536_2048px.webp",
    "thumbnail": "interieur/20220521_123519-2048x1536_2048px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.062-1536x864_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.062-1536x864_1536px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.061-1536x864_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.061-1536x864_1536px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.06-1536x864_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.06-1536x864_1536px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.052-1536x864_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.052-1536x864_1536px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.053-1536x864_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.053-1536x864_1536px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.054-1536x864_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.054-1536x864_1536px.webp"
  },
  {
    "original": "interieur/Image-2024-08-29-at-21.05.05-1536x865_1536px.webp",
    "thumbnail": "interieur/Image-2024-08-29-at-21.05.05-1536x865_1536px.webp"
  }
]
;
