/* eslint-disable react/prop-types */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from "react";

// react-router components
import { Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Material Kit 2 React themes
import theme from "assets/theme";

import { Box, Link, Typography } from "@mui/material";
import TagManager from "react-gtm-module";
import "./i18n"; // Importer le fichier de configuration i18next

import LoadFavicon from "components/LoadFavicon";
import MKButton from "components/MKButton";
import SuspenseSpin from "components/SuspenseSpin";
import config from "config";
import { routes } from "data/data";
import { getArticleRoute } from "lib/articles";
import ContactUsWithData from "pages/ContactUs/ContactUsWithData";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Signup from "pages/Signup";
import Sondage from "pages/Sondage";
import { Navigate } from "react-router-dom";
// import PageInfo from "components/PageInfo";

const CookieConsent = React.lazy(() => import("react-cookie-consent"));
// import CookieConsent from "react-cookie-consent";

const ContactUs = React.lazy(() => import("pages/ContactUs"));
const Presentation = React.lazy(() => import("layouts/pages/presentation"));
const Article = React.lazy(() => import("pages/Presentation/Article"));
const PageInfo = React.lazy(() => import("components/PageInfo"));
// import Presentation from "layouts/pages/presentation";
// import ContactUs from "pages/ContactUs";
// import Article from "pages/Presentation/articles/Article";
// i18n.changeLanguage("en");

const components = {
  Signup,
  Presentation,
  Sondage,
};

const site = process.env.REACT_APP_SITE;

export const copyrightDate = new Date().getFullYear();

const Sitemap = () => {
  window.location.href = "/sitemap.xml";
  return null;
};

const initGTM = () => {
  if (config.gtmEnabled) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", config.gtmId);
  }
};

export default function App() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_path: location.pathname,
        page_title: document.title,
      },
    });
  }, [location]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      const articleRoute = getArticleRoute(route?.name);
      // console.log(">>>>", route.name, route.component,articleRoute )
      if (articleRoute) {
        return [
          <Route exact path={articleRoute} element={route.component} key={route.name} />,
          <Route
            exact
            path={`/articles/${route.name}`}
            element={route.component}
            key={`${route.name}-2`}
          />,
          ...(route.route2
            ? [
                <Route
                  exact
                  path={route.route2}
                  element={<Navigate to={articleRoute} replace />}
                  key={`${route.name}-redirect`}
                />,
              ]
            : []),
        ];
      }

      return null;
    });

  console.log("Environment:", process.env.NODE_ENV);

  return (
    <ThemeProvider theme={theme}>
      <LoadFavicon />
      <CssBaseline />
      <Helmet>
        <title>{t("title")}</title>
        <meta name="description" content={t("summary")} />
      </Helmet>
      <SuspenseSpin>
        <Routes>
          {getRoutes(routes)}
          <Route path="/conditions-generales-utilisation" element={<Article name="cgu" legal />} />
          <Route
            path="/politique-de-confidentialite"
            element={<Article name="confident" legal />}
          />
          <Route path="/politique-cookies" element={<Article name="cookie" legal />} />
          <Route path="/mentions-legales" element={<Article name="mention" legal />} />
          <Route
            path="/contact"
            element={config.displayContactData ? <ContactUsWithData /> : <ContactUs />}
          />
          <Route path="/nouvel-avis" element={<ContactUs avis />} />
          {/* <Route path="/" element={<Presentation />} /> */}
          <Route path="/" element={React.createElement(components[config.home])} />
          {config.affiliate && (
            <Route path="/affiliation" element={<Article name="affiliation" legal />} />
          )}
          {site === "meilleurecrypto" && (
            <Route path="/merci-inscription-binance" element={<PageInfo name="thanks" />} />
          )}
          {site === "petitspas" && (
            <Route path="/confirmation-inscription" element={<PageInfo name="confirmation" />} />
          )}
          {site === "ouvertureuape" && (
            <Route path="/sondage-confirmation" element={<PageInfo name="confirmation" />} />
          )}
          <Route path="/sitemap.xml" element={<Sitemap />} />
          <Route path="*" element={<PageInfo name="notfound" />} />
        </Routes>
      </SuspenseSpin>

      {/* Bannière de consentement */}
      <SuspenseSpin>
        <CookieConsent
          location="bottom"
          style={{
            backgroundColor: "#f8f9fa", // Couleur d'arrière-plan (gris clair)
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          contentStyle={{
            fontSize: theme.typography.body2.fontSize,
            color: "#6c757d", // Couleur du texte (gris doux)
          }}
          buttonWrapperClasses="mui-button-wrapper"
          disableButtonStyles={true}
          ButtonComponent={(props) => (
            <MKButton
              {...props}
              style={{
                marginRight: "10px",
              }}
              variant="contained"
              color="info"
              component={Link}
              to="/"
            ></MKButton>
          )}
          declineButtonComponent={(props) => (
            <MKButton
              {...props}
              style={{
                marginRight: "10px",
              }}
              variant="contained"
              color="info"
              component={Link}
              to="/"
            ></MKButton>
          )}
          enableDeclineButton
          buttonText={t("accep")}
          declineButtonText={t("refuse")}
          onAccept={() => {
            // Si l'utilisateur accepte, on initialise GTM
            window.dataLayer.push({ event: "accept_cookies" });
            initGTM();
          }}
          onDecline={() => {
            window.dataLayer.push({ event: "decline_cookies" });
          }}
          customContainerComponent={({ children }) => (
            <Box display="flex" justifyContent="space-between" width="100%">
              {children}
            </Box>
          )}
        >
          <Typography variant="body2" style={{ color: "#6c757d" }}>
            {t("consent") + " "}
            <a href="/politique-de-confidentialite" className="underline">
              {t("footer.politique-confidentialite")}
            </a>
          </Typography>
        </CookieConsent>
      </SuspenseSpin>
    </ThemeProvider>
  );
}
